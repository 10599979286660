import {
  primaryColor,
  secondaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  title,
  description,
} from "assets/jss/material-kit-pro-react.jsx"

const infoStyle = (theme) => ({
  infoArea: {
    maxWidth: "100%",
    margin: "0 auto",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "30px auto 0px auto",
      textAlign: "center",
    },
  },
  primary: {
    color: primaryColor[0],
  },
  secondary: {
    color: secondaryColor[0],
  },
  warning: {
    color: warningColor[0],
  },
  danger: {
    color: dangerColor[0],
  },
  success: {
    color: successColor[0],
  },
  info: {
    color: infoColor[0],
  },
  rose: {
    color: roseColor[0],
  },
  gray: {
    color: grayColor[0],
  },
  faIcon: {
    transform: "scale(.857)",
  },
  icon: {
    width: "2.25rem !important",
    height: "2.25rem",
    fontSize: "2.25rem",
  },
  descriptionWrapper: {
    color: grayColor[0],
    overflow: "hidden",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  title: {
    ...title,
    margin: "1.75rem 0 0.875rem !important",
    minHeight: "unset",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0px !important",
    },
  },
  description: {
    ...description,
    marginBottom: "0px",
  },
  iconWrapperVertical: {
    float: "none",
  },
  iconVertical: {
    width: "61px",
    height: "61px",
  },
})

export default infoStyle
