import {
  faArchway,
  faCalendarDay,
  faGlassCheers,
} from "@fortawesome/pro-duotone-svg-icons"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import publicTastingAboutStyle from "assets/jss/material-kit-pro-react/views/publicTastingPageSections/publicTastingAboutStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import InfoArea from "components/InfoArea/InfoArea.jsx"
// gatsby libraries
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(publicTastingAboutStyle)

export default function PublicTastingAbout() {
  const { publicTastingAboutImage1 } = useStaticQuery(graphql`
    query publicTastingAboutImages {
      publicTastingAboutImage1: file(
        relativePath: { eq: "public-tasting/public-tasting-1.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} sm={8} md={8}>
            <h2 className={classes.title}>It’s a chili free-for-all!</h2>
          </GridItem>
        </GridContainer>
        <GridContainer alignItems="center">
          <GridItem xs={12} sm={12} md={5} className={classes.mrAuto}>
            <GatsbyImage
              image={publicTastingAboutImage1.childImageSharp.gatsbyImageData}
              className={classes.publicTastingAboutImage}
              alt="Bloody Mary Picture"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={7} className={classes.mlAuto}>
            <InfoArea
              className={classes.info4}
              title="Where"
              description="Throughout the morning and early afternoon, anticipation of the public chili tasting grows with the grill smoke and mouth-watering aromas that fill the air."
              faIcon={faArchway}
              iconColor="primary"
            />
            <InfoArea
              className={classes.info4}
              title="When"
              description="When the clock hits 3 PM and the band on stage takes a short break, crowds gather round to receive a tasting cup and spoon for the public chili tasting, along with a ballot for the People’s Choice Chili award."
              faIcon={faCalendarDay}
              iconColor="secondary"
            />
            <InfoArea
              className={classes.info4}
              title="Why"
              description="Then, the masses descend on the 50+ chili cooks, spoons and ballots in hand, ready to taste and crown the People’s Choice Chili winner."
              faIcon={faGlassCheers}
              iconColor="primary"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
